import axios from "axios";
import React, { useState } from "react";
import PasswordInput from "../Global/PasswordInput";

function ContactTable() {
  const [password, setPassword] = useState("");
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [data, setData] = useState([]);
  console.log("🚀 ~ ContactTable ~ data:", data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    console.log("🚀 ~ handlePasswordSubmit ~ password:", password);
    try {
      // Replace 'YOUR_BACKEND_URL' with your actual backend URL
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/contact-us-form`,
        {
          password: password,
        },
      );

      console.log("🚀 ~ handlePasswordSubmit ~ response:", response);
      if (!response.data) {
        throw new Error("Incorrect password or network error");
      }

      setData(response.data); // Assuming result is an array of contact data
      setPasswordSubmitted(true);
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  if (!passwordSubmitted) {
    return (
      <div className="flex items-center justify-center h-screen">
        <form
          onSubmit={handlePasswordSubmit}
          className="bg-white p-6 rounded shadow-md"
        >
          <h2 className="text-2xl mb-4">Enter Password</h2>
          {error && <p className="text-red-500 mb-2">{error}</p>}

          <PasswordInput
            label="Password"
            placeholder="Enter password"
            value={password}
            handleOnChange={(e) => setPassword(e.target.value)}
            isRequired={true}
          />
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 w-full rounded hover:bg-blue-600"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="p-4">
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            <th className="border px-4 py-2 text-left">Name</th>
            <th className="border px-4 py-2 text-left">Email</th>
            <th className="border px-4 py-2 text-left">Phone Number</th>
            <th className="border px-4 py-2 text-left">Note</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((contact, index) => (
            <tr key={index} className="border">
              <td className="border px-4 py-2">{contact.name || ""}</td>
              <td className="border px-4 py-2">{contact.email || ""}</td>
              <td className="border px-4 py-2">{contact.phone || ""}</td>
              <td className="border px-4 py-2">{contact.note || ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ContactTable;
