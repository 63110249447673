import React, { useState, useRef, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "../../styles/RecordingArea.css";
import Loader from "./Loader";
import axios from "axios";
import RecordGreenIcon from "../../assets/record-icon-green.png";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import useRecordingStore from "../../utils/recordingStore";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../utils/userStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PatientInformation from "./PatientInformation";
import RecordingSection from "./RecordingSection";
import TranscriptSection from "./TranscriptSection";
import GeneratedPromptView from "./GeneratedPromptView";
import { trackEvent } from "../../firebase";
import { jwtDecode } from "jwt-decode";

const RecordingArea = () => {
  const [isPatientForm, setIsPatientForm] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [pauseRecording, setPauseRecording] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [userTranscript, setUserTranscript] = useState("");
  const [patientName, setPatientName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const streamRef = useRef(null);
  // const [audioBlob, setAudioBlob] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formattedSOAP, setFormattedSOAP] = useState();
  const [formattedPromptSOAP, setFormattedPromptSOAP] = useState();
  const setSearchName = useRecordingStore((state) => state.setSearchName);
  const [tempSearchName, setTempSearchName] = useState("");
  const [selectedOption, setSelectedOption] = useState("SOAP");
  console.log("🚀 ~ RecordingArea ~ selectedOption:", selectedOption);
  const mediaRecorderRef = useRef(null);
  const [reloadData, setReloadData] = useState(false);
  const currentUser = useUserStore((state) => state.currentUser);
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  console.log(
    "🚀 ~ RecordingArea ~ selectedSpecialization:",
    selectedSpecialization,
  );
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const speechRecognitionRef = useRef(null);
  const [transcriptionText, setTranscriptionText] = useState("");
  const recorderIntervalRef = useRef(null);
  const [recordingId, setRecordingId] = useState(null);
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    if (isRecording && !pauseRecording && apiKey) {
      startRecording();
    } else {
      stopRecording();
    }
    // Cleanup on component unmount
    return () => {
      stopRecording();
    };
  }, [isRecording, pauseRecording, apiKey]);

  useEffect(() => {
    if (selectedRecord?.id) setUserTranscript(selectedRecord?.transcript);
  }, [selectedRecord]);

  // Check if speech recognition is supported
  // useEffect(() => {
  //   if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
  //     toast.error("Browser doesn't support speech recognition.");
  //   }
  // }, []);

  useEffect(() => {
    if (selectedRecord?.id) {
      setIsPatientForm(true);
      stopRecording();

      setFormattedSOAP(selectedRecord.soap_note);
      if (selectedRecord.soap_note1) {
        setFormattedPromptSOAP(selectedRecord.soap_note1);
      } else {
        setFormattedPromptSOAP("");
      }
      setPatientName(selectedRecord.patient_name);
    }
  }, [selectedRecord]);

  useEffect(() => {
    async function getDeepgramApiKey() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BE_DOMAIN}/deepgram`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          const token = data.token;

          const decoded = jwtDecode(token);
          const key = decoded.apiKey;

          setApiKey(key);
        } else {
          const errorData = await response.json();
          console.error("Error:", errorData);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }

    if (isRecording) {
      let isMounted = true;
      getDeepgramApiKey();

      const intervalId = setInterval(
        () => {
          getDeepgramApiKey();
        },
        1 * 60 * 1000 + 50 * 1000,
      );

      return () => {
        isMounted = false;
        clearInterval(intervalId);
      };
    }
  }, [isRecording]);

  useEffect(() => {
    setUserTranscript(transcriptionText);
  }, [transcript]);

  const startRecording = async () => {
    if (pauseRecording) {
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const options = { mimeType: "audio/webm" };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      console.error(`${options.mimeType} is not supported on your browser.`);
      return;
    }

    mediaRecorderRef.current = new MediaRecorder(stream, options);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      async (event) => {
        console.log("🚀 ~ event:", event);
        if (event.data.size > 0) {
          console.log("Blob type:", event.data.type);
          // Send the audio chunk for transcription

          await sendAudioForTranscription(event.data);
        }
      },
    );

    mediaRecorderRef.current.addEventListener("stop", () => {
      // After stopping, start a new recording if still recording and not paused
      if (isRecording && !pauseRecording) {
        mediaRecorderRef.current.start();
      }
    });

    mediaRecorderRef.current.start();

    // Stop and restart the recorder every 5 seconds
    recorderIntervalRef.current = setInterval(() => {
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.stop();
      }
    }, 5000);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== "inactive") {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    if (recorderIntervalRef.current) {
      clearInterval(recorderIntervalRef.current);
    }
  };

  const resetRecordingState = () => {
    stopRecording();
    setAudioChunks([]);
    // setAudioBlob(null);
    resetTranscript();
    setPatientName("");
    setSelectedSpecialization("");
    setUserTranscript("");
    setFormattedSOAP();
    setFormattedPromptSOAP();
    setIsRecording(false);
    setSelectedRecord(null);
    setRecordingId(null);
    setIsPatientForm(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    stopRecording();
    const auth = getAuth();
    const user = auth.currentUser;

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `audios/${user.uid}_${patientName}_${Date.now()}.wav`,
    );

    try {
      // const snapshot = await uploadBytes(storageRef, audioBlob);
      // const audioURL = await getDownloadURL(snapshot.ref);
      const audioURL = "sbf";

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/audio`,
        {
          transcript: userTranscript,
          audioUrl: audioURL,
          patientName: patientName,
          userId: user.uid,
          noteType: selectedOption,
          selectedSpecialization: selectedSpecialization,
          physicianName: currentUser.name,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        },
      );

      if (response.data) {
        console.log("🚀 ~ handleSubmit ~ response.data:", response.data);
        // setSoapNotes(response.data.soapNotes);
        trackEvent("User", "Recording", "Creating new Recording");
        setRecordingId(response.data.recordingId);
        setFormattedSOAP(response.data.soapNotes);
        if (response.data.soapNotes1) {
          setFormattedPromptSOAP(response.data.soapNotes1);
        } else {
          setFormattedPromptSOAP("");
        }
      }

      toast.success("Audio and transcript submitted successfully");

      setReloadData(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReSubmit = async (transcriptId) => {
    setIsLoading(true);
    stopRecording();
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/audio/update`,
        {
          transcriptId: transcriptId,
          transcript: userTranscript,
          userId: user.uid,
          physicianName: currentUser.name,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        },
      );

      if (response.data) {
        console.log("🚀 ~ handleSubmit ~ response.data:", response.data);
        // setSoapNotes(response.data.soapNotes);
        trackEvent("User", "Recording", "Updated Recording");

        setFormattedSOAP(response.data.soapNotes);
        if (response.data.soapNotes1) {
          setFormattedPromptSOAP(response.data.soapNotes1);
        } else {
          setFormattedPromptSOAP("");
        }
      }

      toast.success("Audio and transcript submitted successfully");

      setReloadData(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameSearch = () => {
    setSearchName(tempSearchName);
  };

  const handleTranscriptChange = (e) => {
    setUserTranscript(e.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const sendAudioForTranscription = async (audioBlob) => {
    const contentType = audioBlob.type || "application/octet-stream";
    console.log("🚀 ~ sendAudioForTranscription ~ contentType:", contentType);

    try {
      const response = await fetch(
        `https://api.deepgram.com/v1/listen?model=nova-2&punctuate=true&language=${selectedLanguage}`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${apiKey}`,
            "Content-Type": contentType,
          },
          body: audioBlob,
        },
      );

      if (!response.ok) {
        const errorData = await response.text();
        console.error("Deepgram API error:", errorData);
        return;
      }

      const data = await response.json();
      handleTranscriptionResult(data);
    } catch (error) {
      console.error("Error sending audio to Deepgram:", error);
    }
  };

  const handleTranscriptionResult = (data) => {
    if (data && data.results && data.results.channels[0].alternatives[0]) {
      const transcript = data.results.channels[0].alternatives[0].transcript;
      setTranscriptionText((prev) => prev + " " + transcript);
      setUserTranscript((prev) => prev + " " + transcript);
    }
  };

  const handlePause = () => {
    setIsRecording((prev) => !prev);
    if (pauseRecording) {
      setPauseRecording(false);
    }
  };

  const handleSubmitResponse = () => {
    selectedRecord?.id
      ? handleReSubmit(selectedRecord?.id)
      : recordingId
        ? handleReSubmit(recordingId)
        : handleSubmit();
  };

  return (
    <RecordingAreaLocked
      isLocked={currentUser?.status === "cancel" ? true : false}
    >
      {isLoading && <Loader showText={true} />}
      <div className="flex flex-wrap">
        <RecordingSection
          handleNameSearch={handleNameSearch}
          reloadData={reloadData}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          tempSearchName={tempSearchName}
          setTempSearchName={setTempSearchName}
        />

        <div className="w-full md:w-[77%] flex justify-center order-1 md:order-2">
          {!isPatientForm ? (
            <PatientInformation
              patientName={patientName}
              setIsPatientForm={setIsPatientForm}
              setPatientName={setPatientName}
              setPauseRecording={setPauseRecording}
              setSelectedLanguage={setSelectedLanguage}
              startRecording={startRecording}
              setIsRecording={setIsRecording}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              setSelectedSpecialization={setSelectedSpecialization}
            />
          ) : (
            <div className="w-full">
              <div className="flex justify-between py-4 px-2 items-center border-b border-x-customBlack-light">
                <div>
                  <p className="text-xl font-semibold">{patientName}</p>
                </div>
                <div className="flex gap-2 items-center">
                  {selectedRecord?.id && (
                    <>
                      <a
                        href={selectedRecord?.audio_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={RecordGreenIcon}
                          alt="record icon"
                          className="cursor-pointer"
                        />
                      </a>
                      <SecondaryButton
                        label={"Start new Recording"}
                        onClick={resetRecordingState}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap">
                <TranscriptSection
                  selectedRecord={selectedRecord}
                  pauseRecording={isRecording}
                  userTranscript={userTranscript}
                  formattedPromptSOAP={formattedPromptSOAP}
                  selectedOption={selectedOption}
                  handleOptionChange={handleOptionChange}
                  resetRecordingState={resetRecordingState}
                  setSelectedSpecialization={setSelectedSpecialization}
                  handleTranscriptChange={handleTranscriptChange}
                  handlePause={handlePause}
                  handleSubmitResponse={handleSubmitResponse}
                />
                <GeneratedPromptView
                  formattedPromptSOAP={formattedPromptSOAP}
                  formattedSOAP={formattedSOAP}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </RecordingAreaLocked>
  );
};

const RecordingAreaLocked = ({ isLocked, children }) => {
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLocked) openDialog();
  }, [isLocked]);

  const openDialog = () => {
    setDialog(true);
  };

  return (
    <div className="relative">
      {isLocked && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded flex  flex-col justify-center items-center gap-3 shadow-lg w-1/2">
            <p className="text-lg">
              Please subscribe to a plan to continue using MD Voice
            </p>

            <PrimaryButton
              label={"Get Started"}
              onClick={() => navigate("/pricing")}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default RecordingArea;
