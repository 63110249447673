import React, { useState } from "react";
import Check from "../../../assets/check.png";
import { PrimaryButton, SecondaryButton } from "../../Global/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useUserStore from "../../../utils/userStore";
import { loadStripe } from "@stripe/stripe-js";
import Input from "../../Global/Input";
import { ToastContainer, toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricing = ({ isLandingPage }) => {
  console.log("🚀 ~ Pricing ~ isLandingPage:", isLandingPage);
  const [promoCode, setPromoCode] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);

  const handleCheckout = async (planId) => {
    const stripe = await stripePromise;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/stripe/create-checkout-session`,
        {
          userId: currentUser.user_id,
          priceId: planId,
          promotionCode: promoCode,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        },
      );

      const session = await response.data;

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });
    } catch (e) {
      // if (result.error) {
      // Display error to your customer
      toast.error(e.response.data.error);
      console.log(e.response.data.error);
      // console.error(result.error.message);
      // }
    }
  };

  const options = [
    {
      heading: "$0/mth",
      plan: "0",
      subheading: "Free Plan",
      features: [
        "100 patient encounters /AI Scribes per month",
        "Mobile Apps",
        "Capture every detail with real-time transcription",
        "Generate comprehensive notes instantly.",
        "Secure and confidential data handling.",
        "Phone Support",
      ],
    },
    // {
    //   heading: "$50/mth",
    //   plan: "$50/mth/doc",
    //   subheading: "Promotion Plan",
    //   features: [
    //     "Choose this plan if you have a promotion Code",
    //     "Unlimited AI Scribes",
    //     "Mobile Apps",
    //     "Multiple Languages",
    //     "75% better discount",
    //     "All benefits of the Standard Plan",
    //     "Enjoy a reduced rate with group discounts.",
    //     "Each doctor maintains their own private account.",
    //     "Secure and confidential data handling.",
    //     "Phone Support",
    //   ],
    // },
    {
      heading: "$75/mth",
      plan: "$75/mth/doc",
      subheading: "Standard Plan",
      features: [
        "Unlimited AI Scribes",
        "Mobile Apps",
        "Multiple Languages",
        "62.5% better discount",
        "Capture every detail with real-time transcription.",
        "Generate comprehensive notes instantly.",
        "Secure and confidential data handling.",
        "Record as many conversations as needed.",
        "Phone Support",
      ],
    },
  ];

  return (
    <div className="my-10">
      <p className="text-center text-base text-customRed-text font-semibold">
        Pricing
      </p>
      <p className="text-center text-4xl font-semibold">
        Simple, transparent pricing
      </p>

      <div className="flex flex-col md:flex-row justify-center items-center mt-10 space-y-6 md:space-y-0 md:space-x-8">
        {options.map((option, index) => (
          <PricingOption
            index={index}
            key={index}
            {...option}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            handlePayment={handleCheckout}
          />
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

const PricingOption = ({
  index,
  heading,
  plan,
  subheading,
  features,
  promoCode,
  setPromoCode,
  handlePayment,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const currentUser = useUserStore((state) => state.currentUser);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setPromoCode("");
  };

  const handlePopupSubmit = () => {
    setIsPopupOpen(false);
    handlePayment("price_1QFKSeF7rGdPQAvX4HsfOsmS");
  };

  return (
    <>
      <div className="border p-6 rounded-lg justify-between text-center w-full h-[630px] max-w-md flex flex-col gap-4">
        <div>
          <div className="flex items-center justify-center gap-2">
            {index !== 0 && (
              <div className="relative inline-block">
                <span className="text-4xl font-bold">$200</span>
                <div className="absolute top-1/2 left-0 w-full h-[2px] bg-red-500 transform rotate-[-45deg] origin-center"></div>
              </div>
            )}

            <p className="text-5xl font-bold">{heading}</p>
          </div>
          <p className="text-lg mt-2 font-bold">{subheading}</p>
          <ul className="mt-4 text-left space-y-2 flex flex-col gap-4">
            {features.map((feature, index) => (
              <li
                key={index}
                className="flex items-center text-base text-customBlack-secondary"
              >
                <img src={Check} alt={"Check"} className="mr-2" />
                {feature}
              </li>
            ))}
          </ul>
        </div>

        <PrimaryButton
          className=""
          isDisabled={currentUser.plan}
          label={
            currentUser.plan && plan === currentUser.plan
              ? "Subscribed Plan"
              : "Get Started"
          }
          onClick={() => {
            if (currentUser?.user_id) {
              if (!currentUser.plan) {
                if (heading.includes("75")) {
                  handlePayment("price_1QFKWJF7rGdPQAvXRrOkJC3f");
                } else {
                  handleOpenPopup();
                }
              }
            } else {
              console.log("FFf", plan);
              navigate("/signup", { state: { plan } });
            }
          }}
        />
      </div>
      <PromoCodePopup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        onSubmit={handlePopupSubmit}
      />
    </>
  );
};

const PromoCodePopup = ({
  isOpen,
  onClose,
  promoCode,
  setPromoCode,
  onSubmit,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg md:w-1/3 w-full md:m-0 m-4">
        <h2 className="text-2xl ">Enter Promo Code</h2>
        <Input
          type="text"
          value={promoCode}
          handleOnChange={(e) => setPromoCode(e.target.value)}
          placeholder="Enter your promo code"
        />
        <div className="flex gap-2 justify-end">
          <SecondaryButton label={"Cancel"} onClick={onClose} />
          <PrimaryButton label={"Submit"} onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
