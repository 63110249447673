import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/logo.png";
import { getAuth, signOut } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useUserStore from "../../utils/userStore";

export default function UserNavbar() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);
  console.log("🚀 ~ UserNavbar ~ currentUser:", currentUser);
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);
  const setUid = useUserStore((state) => state.setUid);
  const currentDate = new Date();

  useEffect(() => {
    setUid(userId);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      const auth = getAuth();
      const uuid = auth.currentUser.uid;
      console.log("jo", uuid);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_DOMAIN}/user`,
          {
            params: { uid: uuid ? uuid : userId },
          },
        );
        if (response.data || response) {
          const user = response.data;
          setCurrentUser(user);
          const words = user.name?.trim().split(/\s+/);

          // Map each word to its first character and join them together
          const initials = words?.map((word) => word[0].toUpperCase()).join("");

          setUsername(initials);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserData();
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      navigate("/login");

      console.log("Logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  const getDateKey = (seconds) => {
    const milliseconds = seconds * 1000;
    const date = new Date(milliseconds);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  return (
    <nav className="p-4 border-b border-customGrey-primary">
      <div className="container flex justify-between min-w-full items-center">
        {/* Company Logo */}
        <div className="text-white font-bold text-lg">
          <img src={Logo} alt="Company Logo" className="h-12" />
        </div>
        {currentUser.trail_end_date?._seconds >
          Math.floor(currentDate.getTime() / 1000) && (
          <div>
            <p className="text-red-600">
              {`Your trial will end on:
            ${getDateKey(currentUser.trail_end_date?._seconds)}`}
            </p>
          </div>
        )}
        {/* User Avatar */}
        <div className="flex items-center gap-2">
          {currentUser.promotion_code && (
            <p>Promo code:{currentUser.promotion_code}</p>
          )}
          <div className="relative" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="focus:outline-none">
              <div className="w-10 h-10 rounded-full bg-customRed-background flex items-center justify-center text-customRed-text font-bold">
                {username}
              </div>
            </button>

            {/* Dropdown Menu */}
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-100 rounded-lg z-20">
                <a
                  onClick={() => navigate("/main")}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                >
                  Recording Area
                </a>
                <a
                  onClick={() => navigate("/pricing")}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                >
                  Pricing
                </a>
                <a
                  onClick={() => navigate("/settings")}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                >
                  Settings
                </a>
                <a
                  onClick={handleLogout}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                >
                  Logout
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
