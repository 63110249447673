import React, { useState } from "react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import "../styles/Auth.css";
import Layout from "../components/Layout";
import LoginImg from "../assets/login.jpg";
import Loader from "../components/Main/Loader";
import CryptoJS from "crypto-js";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordInput from "../components/Global/PasswordInput";
import { trackEvent } from "../firebase";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!isValidPhoneNumber(phoneNumber)) {
      setIsLoading(false);
      toast.error("Invalid Phone number");
      return;
    }

    const auth = getAuth();
    try {
      const hashedPassword = CryptoJS.SHA256(password).toString();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/login`,
        {
          password: hashedPassword,
          phone_number: phoneNumber,

          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        },
      );

      const token = response.data.token;
      await signInWithCustomToken(auth, token);
      trackEvent("User", "Login", "User logged in");

      navigate("/main");
    } catch (error) {
      console.log("Error signing up", error.response.data.result);
      toast.error(error.response.data.result);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="auth-container">
          <form className="auth-form md:p-2 p-4" onSubmit={handleLogin}>
            <div className=" flex flex-col gap-4 text-left">
              <div>
                <h1 className="text-h1 font-h1  text-customBlack-primary">
                  Log in
                </h1>
                <p className="text-p font-p text-customBlack-secondary">
                  Welcome back! Please enter your details.
                </p>
              </div>

              <div>
                <p>Phone Number</p>

                <PhoneInput
                  international
                  countryCallingCodeEditable={true}
                  defaultCountry="CA"
                  placeholder={"Phone number"}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  style={{
                    borderRadius: "6px",
                    paddingLeft: "10px",
                    width: "inherit",
                  }}
                />
              </div>
              <div>
                <PasswordInput
                  label="Password"
                  placeholder="Enter password"
                  value={password}
                  handleOnChange={(e) => setPassword(e.target.value)}
                  isRequired={true}
                />
                <Link to="/forgot-password"> Forgot Password?</Link>
              </div>
              <button type="submit">Sign in</button>
              <p>
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </p>
            </div>
          </form>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

const Login = () => {
  return <Layout children={<LoginForm />} img={LoginImg} />;
};

export default Login;
