import React, { useState, useEffect } from "react";
import Iphone from "../assets/iphone16.png";
import Winner from "../assets/winner.jpeg";

function LuckyDraw() {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const targetDate = new Date("November 15, 2024 18:00:00").getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(timer);
        setTimeLeft({});
      } else {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor((distance / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((distance / 1000 / 60) % 60),
          seconds: Math.floor((distance / 1000) % 60),
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-500 to-purple-600 text-white p-4">
      <h1 className="text-5xl font-extrabold mb-8 text-center drop-shadow-md">
        Join Our Monthly Loyalty Draw!
      </h1>
      <img
        src={Iphone}
        alt="iPhone 16"
        className="w-full max-w-xs md:max-w-sm lg:max-w-md mb-8 transform hover:scale-105 transition-transform duration-500 shadow-xl"
      />
      {/* {timeLeft.days !== undefined ? (
        <div className="flex space-x-4 text-center">
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="flex flex-col items-center">
              <div className="bg-white bg-opacity-20 rounded-lg p-4 min-w-[60px] shadow-lg">
                <span className="text-3xl font-bold">{value}</span>
              </div>
              <span className="capitalize mt-2 text-sm">{unit}</span>
            </div>
          ))}
        </div>
      ) : (
      )} */}
      <div>
        <div className="text-center mb-8">
          <p className="text-2xl">The month's lucky draw winner is:</p>
          <p className="text-2xl">Dr. Molud Ali</p>
          <p className="text-2xl">Family Doctor - Manitoba</p>
        </div>
        {/* <img
          src={Winner}
          alt="Winner"
          className="w-full max-w-xs md:max-w-sm lg:max-w-md mb-8 transform hover:scale-105 transition-transform duration-500 shadow-xl"
        /> */}
      </div>
      {/* <footer className="mt-12 mb-6 text-center text-md opacity-75 p-2">
        <p className="text-blue-600">
          The winner will be announced on December 15, 2024. 6PM EST
        </p>
      </footer> */}
      {/* <p>
        All users are automatically entered into our monthly draw. The winner
        will be announced on our website. If you prefer not to participate,
        please contact us at support@mdvoice.ai to opt out.
      </p> */}
    </div>
  );
}

export default LuckyDraw;
