import React, { useState, useEffect, useRef } from "react";
import Abounaja from "../../../assets/dr-abo-naaja.jpg";
import DrKhaled from "../../../assets/dr-khaled-mr.jpg";
import Amr from "../../../assets/Amr.png";
import DrMuhammed from "../../../assets/dr-khaled.jpg";

const testimonials = [
  {
    image: Abounaja,
    name: "Dr. Mohamed Abounaja",
    speciality: "Family Doctor, Toronto, ON",
    text: "MD Voice is a great addition to my practice! It saves me hours each week, which means more time with my patients. The app is easy to use and supports multiple languages. I highly recommend it for busy family doctor!",
  },
  {
    image: DrKhaled,
    name: "Dr Khaled Isselmou",
    speciality: "Cardiologist, Montréal",
    text: "It is a very good app that uses AI to help write efficient notes, save time, and is accurate and easy to use!",
  },
  {
    image: Amr,
    name: "Dr. Amr El Shahed",
    speciality: "Pediatrician Toronto ON",
    text: "MD Voice is easy to use, captures conversations accurately, and saves time.",
  },
  {
    image: DrMuhammed,
    name: "Dr. Khalid Muhammed",
    speciality: "Family Doctor, Mississauga, ON",
    text: "MD-Voice is helpful app! it understands medical terminology. The transcription accuracy is impressive, and the generated SOAP notes are comprehensive and well-formatted making documentation so much easier.",
  },
  // Add more testimonials as needed
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialCount = testimonials.length;
  const intervalRef = useRef(null);

  const startAutoPlay = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialCount);
    }, 5000); // Change testimonial every 3 seconds
  };

  const stopAutoPlay = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    startAutoPlay();
    return () => {
      stopAutoPlay();
    };
  }, []);

  const handlePrevious = () => {
    stopAutoPlay();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialCount - 1 : prevIndex - 1,
    );
    startAutoPlay();
  };

  const handleNext = () => {
    stopAutoPlay();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialCount);
    startAutoPlay();
  };

  const { image, name, speciality, text } = testimonials[currentIndex];

  return (
    <div className="relative flex flex-col items-center justify-center p-6  md:h-96 bg-gray-100 rounded-lg">
      {/* Left Arrow */}
      <button
        onClick={handlePrevious}
        className="absolute left-0 transform -translate-y-1/2 top-1/2 p-2 focus:outline-none"
      >
        <svg
          className="h-8 w-8 text-gray-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      {/* Testimonial Content */}
      <img
        src={image}
        alt={name}
        className="w-40 h-40 rounded-full object-cover mb-4"
      />
      <h3 className="text-2xl font-semibold">{name}</h3>
      <p className="text-customRed-text font-medium">{speciality}</p>
      <p className="mt-4 text-center text-gray-700 w-1/2">{`"${text}"`}</p>

      {/* Right Arrow */}
      <button
        onClick={handleNext}
        className="absolute right-0 transform -translate-y-1/2 top-1/2 p-2 focus:outline-none"
      >
        <svg
          className="h-8 w-8 text-gray-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

export default TestimonialCarousel;
